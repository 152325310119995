/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'
import PricingCard from './PricingCard'

function VaccinationPricing() {

  return (
    <section className="pricing-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Price List</h5>
                <h2 className="title">Dog / Puppy Vaccine Packages</h2>

              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Puppy Package 1" price="62" label1="6-10 week Vaccine" availability="Monday - Saturday"/>
            <PricingCard title="Puppy Package 2" price="75" label1="10-14 week Vaccine" availability="Monday - Saturday"/>
            <PricingCard title="Puppy Package 3" price="75" label1="14-16 week Vaccine" availability="Monday - Saturday"/>
            <PricingCard title="Annual Dog Vaccine Package" price="115" label1="Canine Adult Vaccine Package" availability="Monday - Saturday"/>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <h2 className="title">Cat / Kitten Vaccine Packages</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Kitten Package 1" price="62" label1="6-10 week Vaccine" availability="Monday - Saturday"/>
            <PricingCard title="Kitten Package 2" price="62" label1="10-14 week Vaccine" availability="Monday - Saturday"/>
            <PricingCard title="Kitten Package 3" price="72" label1="14-16 week Vaccine" availability="Monday - Saturday"/>
            <PricingCard title="Annual Cat Vaccine Package" price="95" label1="Feline Adult Vaccine Package" availability="Monday - Saturday"/>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <h2 className="title">Individual Vaccine Services</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Fecal Test" price="28" label1="CANINE & FELINE" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="DHPP" price="28" label1="Canine" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="Rabbies Vaccine" price="28" label1="CANINE & FELINE" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="Bordetella Vaccine" price="25" label1="Oral" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="FVRCP" price="25" label1="FVRCP" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="FELV / FIV Test" price="38" label1="FELV / FIV Test" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="FELV vaccine" price="38" label1="FELV vaccine 1 year" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="Canine Parvo Test" price="42" label1="Canine Parvo Test" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
            <PricingCard title="LEPTO VACCINE" price="25" label1="LEPTO VACCINE" label2="+" label3="physical exam required ($45)" availability="Monday - Saturday"/>
          </div>
        </div>
    </section>
  )
}

export default VaccinationPricing;
